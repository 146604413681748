import React from 'react'
import styled from 'styled-components'

const HeadContent = styled.div`
    display:grid;
    grid-template-columns:repeat(10,4vw);
    grid-template-rows:repeat(10,10vh);
    `
const BackImage = styled.div`
   background:url(${props=>props.image});
   background-size:cover;
   width:3vw;
   height:3vw;
   grid-column:2/10;
   grid-row:2/10;
   `

/*顶部标题内容区域*/

const HeaderContent = styled.div`
    grid-column:2/10;
   grid-row:3/10;`

const HeaderTitle = styled.h1`
    font-family:var(--header-font-family);
    font-size:var(--Caption);
    font-weight:800;
    background: -webkit-linear-gradient(-64deg,#2B2A30 0%,
        #9F9DA9 21.35%,
        #2B2A30 41.15%,
        #B4B3BC 58.33%,
        #2B2A30 70.83%,
        #9F9DA9 86.46%,
        #2B2A30 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    `

const SplitContent = styled.div`
    display:grid;
    grid-template-columns:repeat(2,1fr);
    `

const Splitline = styled.div`
    width:15vw;
    height:0.5px;
    background:var(--brown_dark);
    opacity:0.1;
    align-self:center;
    justify-items:center;`

const SplitInfo = styled.h3`
    font-family:var(--header-font-family);
    font-size:var(--H3);
    font-weight:800;
    color:var(--brown_dark);
    letter-spacing:4;
`

/*基础内容信息*/
const HeadDetails = styled.div`
    max-width:20vw;
    display:grid;
    grid-template-columns:repeat(2,1fr);
    grid-template-rows:repeat(3,1fr);

    font-family:var(--body-font-family);
    font-size:var(--Body);
    font-weight:700;
    color:var(--brown_dark);

    grid-column:2/10;
    grid-row:8/10;
    

    `

const DetailsTitle = styled.div`
    text-transform:uppercase;
    letter-spacing:0.4px;
    font-weight:800;
    `

const Date = styled.p`
    margin:2vh 0;`

const Role = styled.p`
    margin:2vh 0;
`

const Participant = styled.p`
    margin:2vh 0;
`

const DetailsContent = styled.div`
`

const DateTime = styled.p`
    margin:2vh 0;
`
const RoleName = styled.p`
    margin:2vh 0;
`

const ParticipantAvatar = styled.div`
    display:grid;
    grid-template-columns:repeat(5,0.8vw);
    `

const Avatar01 = styled.div`
    background:url(${props=>props.image});
    background-size:cover;
    width:0.8vw;
    height:0.8vw;
    border:2px solid var(--grey03);
    border-radius:2vw;
    z-index:100;
    `
const Avatar02 = styled.div`
    background:url(${props=>props.image});
    background-size:cover;
    width:0.8vw;
    height:0.8vw;
    border:2px solid var(--grey03);
    border-radius:2vw;`
const Avatar03 = styled.div`
    background:url(${props=>props.image});
    background-size:cover;
    width:0.8vw;
    height:0.8vw;
    border:2px solid var(--grey03);
    border-radius:2vw;`
const Avatar04 = styled.div`
    background:url(${props=>props.image});
    background-size:cover;
    width:0.8vw;
    height:0.8vw;
    border:2px solid var(--grey03);
    border-radius:2vw;`
const Avatar05 = styled.div`
    background:url(${props=>props.image});
    background-size:cover;
    width:0.8vw;
    height:0.8vw;
    border:2px solid var(--grey03);
    border-radius:2vw;`


const ShowcaseHead = props => (
    <div>
    <HeadContent>
        
        <HeaderContent>
            <HeaderTitle>{props.title}</HeaderTitle>
            <SplitContent>
                <Splitline></Splitline>
                <SplitInfo>{props.info}</SplitInfo>
            </SplitContent>
        </HeaderContent>
        <HeadDetails>
            <DetailsTitle>
                <Date>{props.date}</Date>
                <Role>{props.role}</Role>
                <Participant>{props.participant}</Participant>
            </DetailsTitle>
            <DetailsContent>
                <DateTime>{props.time}</DateTime>
                <RoleName>{props.rolename}</RoleName>
                <ParticipantAvatar>
                    <Avatar01 image={props.avatar01}></Avatar01>
                    <Avatar02 image={props.avatar02}></Avatar02>
                    <Avatar03 image={props.avatar03}></Avatar03>
                    <Avatar04 image={props.avatar04} ></Avatar04>
                    <Avatar05 image={props.avatar05}></Avatar05>
                </ParticipantAvatar>
            </DetailsContent>
        </HeadDetails>
    </HeadContent>
    </div>
)

export default ShowcaseHead