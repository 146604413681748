import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import ShowcaseHead from "../components/ShowcaseHeader"
import styled from '../components/Smartisan.css'


class SmartisanShowcase extends React.Component {
  render(){
    return(
        <div>
        <Link to="/Showcase"><div className="BackArrow"></div></Link>      
      <div className="Smartisan">
        <div className="InnerSmartisan">
            <div className="Card Card01">
                <div className="HeadContent">
                    <ShowcaseHead 
                        back="http://qfxaodfpw.hn-bkt.clouddn.com/button_arrow_back.png"
                        title = "Smartisan OS 6.6.6"
                        info="Application"
                        date="Date"
                        role="Role"
                        participant="Participant"
                        time="2018.09 - 2019.05"
                        rolename="Product Designer"
                        avatar01="http://qfxaodfpw.hn-bkt.clouddn.com/avatar%2001.png"
                        
                    />                                    
                </div>
                <div className="HeadStructure">
                        <h1>Mobile Phone <br/>Application Design</h1>
                        <div className="Structure">
                            <div className="StructureContent Structure01">
                                <div className="Thumbnail01"></div>
                                <div className="Thumbnail01Content">
                                    <h3>System・Camera</h3>
                                    <p>Combines a variety of advanced algorithms.</p>
                                </div>
                                <div className="Thumbnail02"></div>
                                <div className="Thumbnail02Content">
                                    <h3>System・Album</h3>
                                    <p>Smartisan OS album combines a variety of advanced algorithms.</p>
                                </div>
                                <div className="Thumbnail03"></div>
                                <div className="Thumbnail03Content">
                                    <h3>TNT・Video</h3>
                                    <p>Simple, Efficient and Beautiful Smartisan OS mobile phone operating system.</p>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
           
            <div className="Card Card02">
                <div className="DeviceName">
                    <h3>坚果 Pro2</h3>
                    <div className="Splitline"></div>
                    <h3>坚果 3</h3>
                    <div className="Splitline"></div>
                    <h3>坚果 R1</h3>
                    <div className="Splitline"></div>
                    <h3>坚果 TNT 工作站</h3>
                    <div className="Splitline"></div>
                    <h3>坚果 Pro2s</h3>
                </div>
                <div className="DeviceInfo">
                    <p>Participate in the software design of the above equipment</p>
                </div>
            </div>

            <div className="Card Card03">
                <div className="CameraMode"></div>                
                    <div className="CardCaption01">
                        <p>01<span>/03</span></p>
                        <h1>System Camera</h1>
                        <div className="Add"></div>
                        <h3><span>Smartisan OS cameras, combined with advanced algorithms, can provide a better photo experience with </span>different scenes<span> such as video, night, self-tape, and large aperture.</span></h3>
                        <div className="CardCaption01Info">
                            <div className='InfoTitle'>
                                <p>Nightview Mode</p>
                            </div>                            
                            <div className="SplitLine"></div>                            
                        </div>
                    </div>            
            </div>

            <div className="Card Card04">
                <div className="CameraFeature"></div>
                <div className="Caption01">
                    <p>02<span>/03</span></p>
                    <h1>02A <span>Features</span></h1>
                    <div className="Add"></div>
                    <h3>Automatic HDRs <span>automatically open in bright and darker scenarios to ensure that bright and dark details are not lost.</span></h3>
                    <h3>Arcsoft <span>background falsification and beauty algorithms to achieve a more excellent film experience</span></h3>
                </div>
            </div>

            <div className="Card Card05">
                <div className="AlbumContent">
                    <div className="Album01"></div>
                    <div className="Album02"></div>
                    <div className="Album03"></div>
                    <div className="Album04"></div>
                    <div className="Album05"></div>
                    <div className="Album06"></div>
                </div>
                <div className="Caption02">
                    <p>03<span>/03</span></p>
                    <h1>System Album</h1>
                    <div className="Add"></div>
                    <h3><span>The camera uses the Arcsoft Smart Beauty Algorithm to match the best color for different faces.</span>Automatic HDR, <span>the algorithm will optimize the dark and bright parts of the photo, so that the details are not lost</span></h3>
                    <div className="CaptionInfo02">
                        <div className="SplitLine"></div>
                        <p>Smartisan OS</p>
                    </div>
                </div>
            </div>

            <div className="Card Card06">
                <div className="Caption03">
                    <p>3 in 1</p>
                    <h1>Smartisan OS 6.6.6</h1>
                    <div className="Add"></div>
                    <h3><span>From a </span>simple, efficient <span>and</span> beautiful <span>Smartisan OS mobile phone operating system, it will bring you unprecedented experience of big screen manipulation and much more efficient than traditional desktop systems.</span></h3>
                    <h3><span>A global </span>gesture + voice<span> interaction system, and seamless switching technology for large screens and mobile phones.</span></h3>
                    <div className="TNTInfo">
                        <div className="Logo"></div>
                        <div className="TNTDescription">
                            <h3>坚果 R1 ・坚果 TNT 工作站（Display）</h3>
                            <p>Cool Features</p>
                        </div>
                    </div>
                </div>
                <div className="TNTImageview"></div>
                <div className="TNT_Album_Layout">
                    <div className="LayoutTop">
                        <div className="TopTitle">
                            <p>3 in 1</p>
                            <div className="SplitLine"></div>
                        </div>
                        <h3>Smartisan OS <span>fully adapts to desktop operating systems.</span></h3>
                    </div>
                    <div className="LayoutCenter">
                        <div className="LayoutImage"></div>
                        <div className="LayoutContent">
                            <div className="Layout01">
                                <p>1/2</p>
                                <div className="Layout01Image"></div>
                                <p>Navbar</p>
                            </div>        
                            <div className="Layout02">
                                <p>02</p>
                                <div className="Layout02Image">
                                    <div className="Layout02ImageLeft"></div>
                                    <div className="Layout02ImageRight"></div>
                                </div>
                                <p>Content area</p>
                            </div>      
                        </div>                          
                    </div>
                    <div className="LayoutBottom">
                        <h3>TNT・Album</h3>
                        <div className="LayoutBottomContent">
                            <p>The album has a two-column structure. The navigation on the left provides folder management and quick dragging of pictures. </p>
                            <p>The content display area on the right simplifies the operation process and facilitates quick and easy picture viewing and editing.</p>
                        </div>
                    </div>
                </div>
                <div className="Album_Move"></div>
                <div className="Album_Slide_Content">
                    <div className='Album_Filter'></div>
                    <div className="Album_Slide_Info">
                        <div className="Album_Slide_Info_Title">
                            <h3>Materials Processing</h3>
                            <p>The editing page adopts a single-column structure, which retains the operating part and layout of the mobile terminal, reducing development costs.</p>                        
                        </div>
                       <div className="SlideInfo">
                            <div className="Add"></div>
                            <div className="Slide"></div>
                        </div>
                    </div>
                </div>
                <div className="Album_Choose"></div>
            </div>

            <div className="Card Card07">
                <div className="TNT_Video">
                    <div className="TNT_Video_Capture">
                        <div className="Video_Player"></div>
                    </div>
                    <div className="Video_Info">
                        <p>No Boundary ・Video player</p>
                        <p><span>Full View Appearnce</span></p>
                        <p>2017 - 2018</p>
                    </div>                    
                </div>
                <div className="Video_Feature">
                        <div className="Circle"></div>
                        <div className="Feature_Content">
                            <div className="Video_Feature01">
                                <h3>Space</h3>
                                <p>A wider vison</p>
                            </div>
                            <div className="Video_Feature02">
                                <h3>Immersion</h3>
                                <p>Borderless design</p>
                            </div>
                            <div className="Video_Feature03">
                                <h3>Convenient </h3>
                                <p>Operating experience</p>
                            </div>
                        </div>
                </div>

                <div className="Video_Operate">
                    <div className="Video_Operate_Content">
                        <div className="Operate_Content01">
                            <h3>The video player uses a single-column structure, and the full-screen display provides an immersive playback experience.</h3>
                        </div>                        
                        <div className="Operate_Content02">
                            <div className="Add"></div>        
                            <div className="SplitLine"></div>
                            <p>VIDEO PLAYER</p>
                        </div>
                        <div className="Operate_Content03">
                            <h3>Hide the operation information such as the progress bar when not in operation, and click to trigger the operation.</h3>
                        </div>                       
                    </div>
                    <div className="Video_Operate01"></div>
                    <div className="Video_Operate02"></div>
                    <div className="Video_Operate03"></div>
                </div>

            </div>

        </div>
        </div>
     </div>
    )
  }
}
export default SmartisanShowcase
